<template>
<div>
    <div class="comment-list-item" :class="{childrenItem:isChild}" v-for="(item,index) in list" :key="item.id">
        <div class="comment-list-item-photo">
            <img :src="item.userImage" alt="">
        </div>
        <div class="comment-list-item-detail">
            <div class="comment-list-item-detail-cont ">
                <p class="comment-list-item-detail-cont-name">{{item.commentuser}}</p>
                <p class="comment-list-item-detail-cont-value">{{item.content}}</p>
                <div class="comment-list-item-detail-cont-info">
                    <p>
                        <span>{{item.createtime}}</span>
                    </p>
                    <p>
                        <span @click="commentFabulous(item)" :class="{active:item.pstatus == 1}">{{item.praiseCount || 0}}</span>
                        <span v-if="!isChild">
                            <span @click="cancelReplay(item)" class="cancel" v-if="replayStatus == index">取消</span>
                            <span @click="replay(index)" v-else>回复</span>
                        </span>
                    </p>
                </div>
                <div class="comment-list-item-reply" v-if="replayStatus==index">
                    <textarea name="" id="" v-model="replayContent" ></textarea>
                </div>
                <p class="comment-list-item-reply-release" v-if="replayStatus==index" @click="replayCont(item)">发布</p>
            </div>
            <comment :data="item.childrenList" @success="success" v-if="item.childrenList.length" :isChild="true"></comment>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name:'comment',
    data(){
        return {
            replayStatus:null,
            list:[],
            replayContent:'',
        }
    },
    props:{
        data:{
            type: Array,
            default:() => ([]),
        },
        isChild:{
            type: Boolean,
            default:false,
        }
    },
    watch:{
        data:{
            handler(newV) {
                this.list = newV;
            },
            deep: true,
        }
    },
    mounted(){
        this.list = this.data;
    },
    methods:{
        replay(val){
            //判断是否登录  未登录需先登录再评论
            if(!this.$store.state.userInfo.token){
                this.$store.commit("setLoginState", "login");
                return;
            }
            this.replayContent = '';
            this.replayStatus =  val;
        },
        cancelReplay(){
            this.replayContent = '';
            this.replayStatus =  null;
        },
        replayCont(item){
            //判断是否登录  未登录需先登录再评论
            if(!this.$store.state.userInfo.token){
                this.$store.commit("setLoginState", "login");
                return;
            }
            let data = {
                contestNewsId: Number(this.$route.query.id),
                content:this.replayContent,
                userId:this.$store.state.userInfo.userId,
                commentId:item.id,    //如果是新闻评论传0 如果是回复传回复的评论id
            }
            if(!this.replayContent){
                this.$notice({
                    message: '请输入评论内容',
                }).isShow();
                return;
            }else if(!this.common.validateLength(this.replayContent,128)){
                this.$notice({
                    message: '请输入正确评论内容',
                }).isShow();
                return;
            }
            this.api.sports.contestComment(data).then((res)=>{
                if(res.flag){
                    this.$emit('success');
                    this.replayContent = '';
                    this.replayStatus =  null;
                    // this.getCommentList();  //获取评论列表
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        commentFabulous(item){
            //判断是否登录  未登录需先登录再评论
            if(!this.$store.state.userInfo.token){
                this.$store.commit("setLoginState", "login");
                return;
            }
            if(item.pstatus == 1){
                this.$notice({
                    message: '请勿重复点赞',
                }).isShow();
                return;
            }
            let data={
                uid:this.$store.state.userInfo.userId
            };
            this.api.sports.commentFabulous(item.id,data).then((res)=>{
                if(res.flag){
                    this.$emit('success');
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        success(){
            this.$emit('success');
        }
    }
}
</script>
<style scoped lang="scss">
.childrenItem{
    padding-top: 20px;
    padding-bottom: 0 !important;
    border-top:1px dashed #DDDDDD;
    border-bottom:none !important;
}
.comment-list-item{
    margin-top:20px;
    padding-bottom: 20px;
    @include flex(row,flex-start,flex-start);

    border-bottom:1px solid #DDDDDD;

    .comment-list-item-photo{
        >img{
            width:48px;
            height:48px;
            border-radius: 50%;
        }
    }
    .comment-list-item-detail{
        margin-left:20px;
        width:734px;
        .comment-list-item-detail-cont{
            

            .comment-list-item-detail-cont-name{
                font-size: 16px;
                color: #BB3037;
            }
            .comment-list-item-detail-cont-value{
                font-size: 16px;
                color: #333333;
                line-height: 30px;
            }
            .comment-list-item-detail-cont-info{
                @include flex(row,space-between,center);
                font-size: 14px;
                color: #BBBBBB;
                    >p:nth-child(2){
                        font-size: 16px;
                        @include flex(row,flex-start,center);
                        >span{
                            cursor:pointer;
                        }
                        >span:nth-child(1){
                            padding-left:22px;
                            background:url('../../../assets/img/sports/Icon_Dz_UnC.png') no-repeat;
                            background-size: 14px 16px;
                        }
                        >span:nth-child(2){
                            padding-left:29px;
                        }
                    >span:nth-child(1).active{
                        background:url('../../../assets/img/sports/Icon_Dz_C.png') no-repeat;
                        background-size: 14px 16px;
                    }
                    }
                    .cancel:hover{
                        color:#BB3037;
                    }
            }
            .comment-list-item-reply{
                margin-top:20px;
                height:100px;
                border: 1px solid #BB3037;
                padding:16px 14px 0 14px;
                border-radius:4px;
                // height:100px;

                >textarea{
                    width:100%;
                    border:none;
                    // line-height: 28px;
                    font-size: 16px;
                    color:#888888;
                    resize: none;
                    overflow-x: hidden;
                    overflow-y: auto;
                    background-image: none;
                    background-attachment: scroll;
                    border: 0;
                    outline: 0;
                    background-color:transparent;
                    height:78px;
                }
            }
            .comment-list-item-reply-release{
                margin:10px 0;
                font-size:16px;
                color:#BB3037;
                text-align:right;
                cursor:pointer;
            }
            .comment-list-item-reply-release:hover{
                color:#f1313b;
            }
        }
        .hasBorder{
            border-top: 1px dashed #DDDDDD;
        }
    }
}
</style>