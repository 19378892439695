<template>
    <div class="main">
        <h1 class="tit">{{info.contestNewsName}}</h1>
        <!-- <div class="sub-tit">
            <span>来源：全国足球特色幼儿园系统平台</span>
            <span>{{info.createTime}}</span>
        </div> -->
        <div class="cont">
            <!-- <img :src="constant.URL + info.coverImage" alt="" v-if="info.coverImage"> -->
            <div v-html="info.context"></div>
        </div>
        <div class="comment">
            <div class="comment-tit">
                <p class="comment-tit-l">
                    <span></span>
                    <span>我有话说</span>
                </p>
                <p class="comment-tit-r">
                    <span @click="giveFabulous" :class="{active:info.pstatus == 1}">{{info.likesNum}}</span>
                    <span>评论({{info.commentNum}})</span>
                </p>
            </div>
            <div class="comment-cont" :class="textareaStatus ? 'active' :''" @click="changeTextaarea">
                <textarea name="" id="" v-model="content" ></textarea>
            </div>
            <button class="comment-btn" v-if="textareaStatus" @click="submitComment">发布</button>
        </div>
        <div class="comment-list" v-if="commentList.length">
            <p class="comment-list-tit">最新评论</p>
            <Comment :data="commentList" @success="commentSuccess"></Comment>
        </div>
    </div>
</template>
<script>
import Comment from './components/comment.vue'
export default {
    name:'news',
    data(){
        return{
            textarea:'',
            textareaStatus:false,
            commentList:[],
            newId:0,   //新闻id
            info:{},
            content:'', //评论内容
        }
    },
    components:{Comment},
    mounted(){
        this.newId = this.$route.query.id;
        this.getNewInfo();
        this.getCommentList();
    },
    methods:{
        changeTextaarea(){
            this.textareaStatus = true;
        },
        getNewInfo(){
            let data={
                uid:this.$store.state.userInfo.userId
            };
            this.api.sports.newsInfo(this.newId,data).then((res)=>{
                if(res.flag){
                    this.info = res.data;
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            })
        },
        giveFabulous(){ //点赞
            //判断是否登录  未登录需先登录再评论
            if(!this.$store.state.userInfo.token){
                this.$store.commit("setLoginState", "login");
                return;
            }
            //判断是否点过赞
            if(this.info.pstatus == 1){
                this.$notice({
                    message: '请勿重复点赞',
                }).isShow();
                return;
            }
            let data={
                uid:this.$store.state.userInfo.userId
            };
            this.api.sports.newsFabulous(this.newId,data).then((res)=>{
                if(res.flag){
                    this.getNewInfo();
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        submitComment(){    //发布评论
            //判断是否登录  未登录需先登录再评论
            if(!this.$store.state.userInfo.token){
                this.$store.commit("setLoginState", "login");
                return;
            }
            let data = {
                contestNewsId: Number(this.newId),
                content:this.content,
                userId:this.$store.state.userInfo.userId,
                commentId:0,    //如果是新闻评论传0 如果是回复传回复的评论id
            }
            if(!this.content){
                this.$notice({
                    message: '请输入评论内容',
                }).isShow();
                return;
            }else if(!this.common.validateLength(this.content,128)){
                this.$notice({
                    message: '请输入正确评论内容',
                }).isShow();
                return;
            }
            this.api.sports.contestComment(data).then((res)=>{
                if(res.flag){
                    this.content = '';
                    this.getNewInfo();  //获取新闻详情
                    this.getCommentList();  //获取评论列表
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        getCommentList(){   //获取评论列表
            let data = {
                uid:this.$store.state.userInfo.userId
            };
            this.api.sports.getCommentList(this.newId,data).then((res)=>{
                if(res.flag){
                    this.commentList = res.data || [];
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            })
        },
        commentSuccess(){   //回复评论成功 重新获取评论列表
            this.getCommentList();
        }
    }
}
</script>
<style lang="scss" scoped>
.main{
    color: #333333;
    margin:0 auto;
    width:1354px;
    padding:0 40px;
    padding-bottom:60px;
    background:#FFFFFF;
    .tit{
        padding-top:40px;
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .sub-tit{
        font-size: 16px;
        text-align: center;
        >span:nth-child(1){
            padding-right:40px;
        }
    }
    .cont{
        margin:25px auto 0 auto;
        width:900px;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        img{
            width:100%;
        }
        > p{
            margin-top: 30px;
        }
    }
    .comment{
        margin:30px auto 0 auto;
        width:800px;
        overflow:hidden;
        .comment-tit{
            @include flex(row, space-between, center);

            .comment-tit-l{
                @include flex(row,flex-start,center);
                >span:nth-child(1){
                    display: inline-block;
                    width: 4px;
                    height: 16px;
                    background: #BB3037;
                    border-radius: 2px;
                }
                >span:nth-child(2){
                    display: inline-block;
                    margin-left:14px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                }
            }
            .comment-tit-r{
                font-size: 16px;
                color: #999999;
                >span:nth-child(1){
                    padding-left:21px;
                    display: inline-block;
                    background: url('../../assets/img/sports/Icon_Dz_UnC.png') no-repeat;
                    background-size: 14px 16px;
                }
                >span:nth-child(1).active{
                    background: url('../../assets/img/sports/Icon_Dz_C.png') no-repeat;
                    background-size: 14px 16px;
                }
                >span:nth-child(2){
                    display: inline-block;
                    padding-left:28px;
                }
            }
        }
        .comment-cont{
            margin-top:20px;
            height:46px;
            border: 1px solid #BB3037;
            padding:16px 14px 0 14px;
            border-radius:4px;
            // height:100px;

            >textarea{
                width:100%;
                border:none;
                // line-height: 28px;
                font-size: 16px;
                color:#888888;
                resize: none;
                overflow-x: hidden;
                overflow-y: auto;
                background-image: none;
                background-attachment: scroll;
                border: 0;
                outline: 0;
                background-color:transparent;
            }
        }
        .comment-cont.active{
            height:100px;

             transition: height .3s;
             >textarea{
                 height:78px;
             }
        }
        .comment-btn{
            float:right;
            margin-top:16px;
            width:100px;
            height:42px;
            background:#bb3037;
            font-size:18px;
            color:#FFFFFF;
            border:none;
            border-radius: 4px;
            cursor:pointer;
        }
        .comment-btn:hover{
            background:#f1313b;
        }
    }
    .comment-list{
        margin:30px auto;
        width:800px;
        .comment-list-tit{
            height:40px;
            font-size: 20px;
            font-weight: bold;
            border-bottom:1px solid #DDDDDD;
        }
        
    }
}
</style>